import { PaletteColorOptions, createTheme } from '@mui/material';
import React from 'react';
import { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

declare module '@mui/material/styles' {
  interface CustomPalette {
    light: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypeText {
    light: string;
    dark: string;
  }

  interface TypeTextOptions {
    light?: string;
    dark?: string;
  }

  interface TypeBackground {
    kindlygray4: string;
    kindlygray3: string;
    kindlygray2: string;
    kindlygray1: string;
    patterned: string;
    patternedContrast: string;
  }

  interface TypeBackgroundOptions {
    kindlygray1?: string;
    kindlygray2?: string;
    kindlygray3?: string;
    kindlygray4?: string;
    patterned: string;
    patternedContrast: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    vsm: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

// this is basically magic that will use the react-router-dom as the underlying component
// for materials link component
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
  },
);

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      vsm: 500,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#1D1183',
      light: '#1D1183',
      dark: '#100947',
      contrastText: '#ffffff',
    },
    light: {
      main: '#ffffff',
    },
    secondary: {
      main: '#C1FB6F',
      contrastText: '#1D1183',
    },
    error: {
      main: '#EF364F',
    },
    warning: {
      main: '#FF5D00',
    },
    success: {
      main: '#63D577',
      light: '#96D600',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F2F2F2',
      patterned: `repeating-linear-gradient(
             -45deg,
            #1D1183, 
            #1D1183 3px, 
            #100947 3px, 
            #100947 6px
          )`,
      patternedContrast: `repeating-linear-gradient(
             -45deg,
            #FFFFFF, 
            #FFFFFF 4px, 
            #D8D8D8 6px, 
            #D8D8D8 6px
          )`,
      kindlygray4: '#F8F9FC',
      kindlygray3: '#EFEFEF',
      kindlygray2: '#D8D8D8',
      kindlygray1: '#838E9F',
    },
    text: {
      primary: '#222833',
      secondary: '#1D1183',
      light: '#FFFFFF',
      dark: '#1D1183',
    },
    // gray: {
    //   primary: '#222833',  // text.primary
    //   secondary: '#D8D8D8', // background.kindlygray2
    //   main: '#EFEFEF', // background.kindlygray3
    //   dark: '#F8F9FC', // background.kindlygray4
    //{{ },
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    fab: 1050,
    mobileStepper: 1000,
    modal: 1300,
    snackbar: 1400,
    speedDial: 1050,
    tooltip: 1500,
  },
  typography: {
    fontFamily: ['Manrope', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        h2: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        h3: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        h4: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        h5: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        h6: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
        body1: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontWeight: 400,
          fontSize: '18px',
        }),
        body2: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        subtitle1: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        subtitle2: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        button: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: { height: '100%' },
        body: { height: '100%' },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 1,
        sx: {
          height: '98px',
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: theme.zIndex.drawer + 1,
          background: theme.palette.background.patterned,
          top: '-1px',
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiIconButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
        sx: {
          textDecoration: 'none',
        },
      },
      styleOverrides: {
        outlined: {
          '&:hover': {
            borderColor: '#1D1183',
          },
        },
        containedSecondary: {
          '&:disabled': {
            backgroundColor: '#C1FB6F90',
          },
        },
        root: {
          size: 'large',
          width: '168px',
          height: '48px',
          textTransform: 'none',
          fontSize: 'large',
          fontWeight: '600',
          borderRadius: 28,
          '&.MuiButton-fullWidth': {
            width: '100%', // Override width when fullWidth is true
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '& input:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiBottomNavigationAction: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#1D1183',
          fontWeight: 500,
          '&.Mui-active': {
            color: '#1D1183',
            fontWeight: 800,
          },
        },
      },
    },
  },
});
