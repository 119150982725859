import React, { createContext, useEffect, useState, useRef } from 'react';
import Keycloak from 'keycloak-js';
import { FormattedUser, useCurrentUserQuery, useUserDeauthentication } from '../common/http/hooks/user';
import { useQueryClient } from 'react-query';
import useStickyState from '../common/hooks/useStickyState';

import Spinner from '../common/components/Spinner/Spinner';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  setAuthToken: React.Dispatch<string | null>;
  logout: () => void;
  user: FormattedUser | null;
  userIsLoading: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
};

const TOKEN_EXPIRATION_THRESHOLD = 60; // seconds
const TOKEN_CHECK_INTERVAL = 30000; // milliseconds

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const keycloak = useRef<Keycloak | null>(null);
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useStickyState('token', null);
  const [refreshToken, setRefreshToken] = useStickyState('refresh_token', null);
  const deauthenticateUser = useUserDeauthentication();
  const queryClient = useQueryClient();
  const { data: user, isLoading: userIsLoading } = useCurrentUserQuery(Boolean(authToken));

  useEffect(() => {
    // Check and process Keycloak authentication parameters on first load
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('session_state') && searchParams.has('code')) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const logout = async () => {
    setAuthToken(null);
    queryClient.removeQueries('currentUser');
    queryClient.removeQueries('me');
    deauthenticateUser.mutate();
    if (keycloak.current) {
      await keycloak.current?.logout();
    }
    localStorage.clear();
  };

  const initKeycloak = async () => {
    const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

    keycloakInstance.onAuthSuccess = () => {
      setAuthToken(keycloakInstance.token ?? null);
    };

    keycloakInstance.onTokenExpired = () => {
      keycloakInstance
        .updateToken()
        .then((refreshed) => {
          if (refreshed) {
            console.log('Token refreshed by onTokenExpired event');
            setAuthToken(keycloakInstance.token ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    };

    keycloakInstance
      .init({
        onLoad: 'check-sso',
        // The below would solve for keycloak reloading the page, but is a no-op for being in a private browser
        // silentCheckSsoFallback: false,
        // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      })
      .then((authenticated: boolean) => {
        if (authenticated && keycloakInstance.token) {
          setAuthToken(keycloakInstance.token);
        }
      })
      .catch((error) => {
        console.error('Keycloak initialization error:', error);
        setAuthToken(null);
      })
      .finally(() => {
        keycloak.current = keycloakInstance;
        setLoading(false);
      });
  };

  // Function to check and refresh the token
  const checkAndRefreshToken = () => {
    console.log('checkAndRefreshToken');
    if (keycloak.current?.authenticated && keycloak.current.isTokenExpired(TOKEN_EXPIRATION_THRESHOLD)) {
      keycloak.current
        .updateToken(TOKEN_EXPIRATION_THRESHOLD)
        .then((refreshed) => {
          if (refreshed) {
            console.log('Token refreshed by checkAndRefreshToken function');
            setAuthToken(keycloak.current?.token ?? null);
            setRefreshToken(keycloak.current?.refreshToken ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    }
  };

  useEffect(() => {
    if (isFirstRun.current) return;
    isFirstRun.current = true;

    initKeycloak();

    // Set up interval to check token expiration every 30 seconds
    const tokenCheckInterval = setInterval(checkAndRefreshToken, TOKEN_CHECK_INTERVAL);

    // Clean up interval on component unmount
    return () => clearInterval(tokenCheckInterval);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        keycloak: keycloak?.current,
        authToken,
        setAuthToken,
        logout,
        user: user || null,
        userIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
