import { useMutation, useQuery, useQueryClient } from 'react-query';
import http from '../http-provider';
import map from 'lodash/fp/map';
import pick from 'lodash/fp/pick';
import invariant from 'tiny-invariant';
import { useCurrentUser } from '../../../contexts/user-context';
import { useMemo } from 'react';
import { readFileToString } from '../../stringUtils';
import { TagStatus } from '../../types';

export interface TagResponse {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media: { file_key: string; file_url: string }[] | null;
  name: string;
  sort_order: number;
  tag_type: string;
}

interface TagGroupResponse {
  children: any;
  id: number;
  key: string;
  name: string;
  tag_group_type: string;
  tags: TagResponse[];
}

// there's other stuff in here but I didn't bother including it
interface ListenerTagResponse {
  id: number;
  tag_id: number;
  status: TagStatus;
}

interface Tag {
  id: number;
  name: string;
}

export interface ProfileTag extends Tag {
  listenerTagId: number | null;
}

export interface BackgroundTag extends Tag {
  status: 'notApplied' | 'pending' | 'approved' | 'rejected';
}

type BackgroundTagDocumentation =
  | { documentationType: 'file'; file: File }
  | { documentationType: 'link'; link: string };

const fetchTopicTags = async () => {
  const { data } = await http.get<TagResponse[]>('/tags/', { params: { tag_type: 'topic' } });
  return data;
};

const BACKGROUND_TAG_GROUP_KEY = 'BACKGROUND';
const PROFILE_TAG_GROUP_KEY = 'PROFILE';
const GROUP_KEY_FILTER = `${BACKGROUND_TAG_GROUP_KEY},${PROFILE_TAG_GROUP_KEY}` as const;

const fetchTraitTagGroups = async () => {
  const { data } = await http.get<TagGroupResponse[]>('/tag_groups/', {
    params: { type: 'trait', keys: GROUP_KEY_FILTER },
  });
  return data;
};

const fetchListenerTags = async (listenerRoleId: number) => {
  const { data } = await http.get<ListenerTagResponse[]>(`/listeners/${listenerRoleId}/listener_tags`, {
    // TODO: could we get rid of this filter if the backend didn't 500 without it?
    params: { tag_group_key: GROUP_KEY_FILTER },
  });
  return data;
};

const addProfileTag = async (listenerRoleId: number, tagId: number) => {
  const { data: newTag } = await http.post<ListenerTagResponse>(`/listeners/${listenerRoleId}/listener_tags`, {
    tag_id: tagId,
  });
  return newTag;
};

const addBackgroundTag = async (listenerRoleId: number, tagId: number, documentation: BackgroundTagDocumentation) => {
  await http.post(`/listeners/${listenerRoleId}/listener_tags`, {
    tag_id: tagId,
    media: [await formatMediaPayload(documentation)],
  });
};

const removeProfileTag = async (listenerRoleId: number, listenerTagId: number) => {
  await http.delete(`/listeners/${listenerRoleId}/listener_tags/${listenerTagId}`);
};

const tagKeys = {
  base: ['tags'] as const,
  listenerTags: (listenerRoleId: number) => [...tagKeys.base, 'listener', listenerRoleId] as const,
  allTags: () => [...tagKeys.base, 'all'] as const,
  topicTags: () => [...tagKeys.allTags(), { type: 'topic' }] as const,
  tagGroups: () => [...tagKeys.allTags()] as const,
  traitTags: () => [...tagKeys.allTags(), { type: 'trait' }] as const,
  optionTags: () => [...tagKeys.allTags(), { type: 'option' }] as const,
};

const formatMediaPayload = async (documentation: BackgroundTagDocumentation) =>
  documentation.documentationType === 'link'
    ? {
        file_key: 'VERIFICATION_LINK',
        file_url: documentation.link,
      }
    : {
        file_key: 'VERIFICATION',
        file_name: documentation.file.name,
        file_url: await readFileToString(documentation.file),
      };

const formatTag: (tag: TagResponse) => Tag = pick(['id', 'name']);

const formatTraitTags = (tagGroups: TagGroupResponse[]) => {
  const profileTagGroup = tagGroups.find(({ key }) => key === PROFILE_TAG_GROUP_KEY);
  invariant(profileTagGroup, 'Profile tags not found');
  const backgroundTagGroup = tagGroups.find(({ key }) => key === BACKGROUND_TAG_GROUP_KEY);
  invariant(backgroundTagGroup, 'Background tags not found');

  return {
    profileTags: profileTagGroup.tags.map(formatTag),
    backgroundTags: backgroundTagGroup.tags.map(formatTag),
  };
};

const formatListenerTag = ({ id, tag_id: tagId, status }: ListenerTagResponse) => ({
  id,
  tagId,
  status,
});

export const isMyStoryTag = ({ is_required: isRequired }: TagResponse) => isRequired;

export const useMyStoryTag = () =>
  useQuery(tagKeys.topicTags(), fetchTopicTags, {
    select: (tags) => formatTag(tags.find((tag) => isMyStoryTag(tag))!),
  });

export const useTopicTags = () =>
  useQuery(tagKeys.topicTags(), fetchTopicTags, {
    select: (tags) => tags.filter((tag) => !isMyStoryTag(tag)).map(formatTag),
  });

export const useTagGroups = () => useQuery(tagKeys.tagGroups(), fetchTagGroups, {});

const useListenerTags = () => {
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'Current user has no listener role');

  return useQuery(tagKeys.listenerTags(listenerRole.id), () => fetchListenerTags(listenerRole.id), {
    select: map(formatListenerTag),
  });
};

const useTraitTags = () => useQuery(tagKeys.traitTags(), fetchTraitTagGroups, { select: formatTraitTags });

export const useTraitTagsForListener = () => {
  const listenerTagsQuery = useListenerTags();
  const traitTagsQuery = useTraitTags();

  return useMemo(
    () => ({
      data: listenerTagsQuery.data &&
        traitTagsQuery.data && {
          profileTags: traitTagsQuery.data.profileTags.map(
            (profileTag): ProfileTag => ({
              ...profileTag,
              listenerTagId: listenerTagsQuery.data.find(({ tagId }) => tagId === profileTag.id)?.id ?? null,
            }),
          ),
          backgroundTags: traitTagsQuery.data.backgroundTags.map((backgroundTag): BackgroundTag => {
            const listenerTag = listenerTagsQuery.data.find(({ tagId }) => tagId === backgroundTag.id);
            const getStatus = () => {
              if (!listenerTag) return 'notApplied';
              if (listenerTag.status === 'APPROVED') return 'approved';
              if (listenerTag.status === 'REJECTED') return 'rejected';
              return 'pending';
            };
            return { ...backgroundTag, status: getStatus() };
          }),
        },
    }),
    [listenerTagsQuery.data, traitTagsQuery.data],
  );
};

const useAddProfileTag = (tagId: number) => {
  const queryClient = useQueryClient();
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'Current user has no listener role');

  const listenerTagsQueryKey = tagKeys.listenerTags(listenerRole.id);
  return useMutation({
    mutationFn: () => addProfileTag(listenerRole.id, tagId),
    onMutate: async () => {
      await queryClient.cancelQueries(listenerTagsQueryKey);
      const previousListenerTags = queryClient.getQueryData<ListenerTagResponse[]>(listenerTagsQueryKey);

      const optimisticTag = { tag_id: tagId, status: TagStatus.APPROVED, id: Math.random() };
      queryClient.setQueryData<ListenerTagResponse[]>(listenerTagsQueryKey, (oldTags) => [
        ...(oldTags ?? []),
        optimisticTag,
      ]);

      return { previousListenerTags, optimisticTag };
    },
    onError: (_err, _vars, context) => {
      queryClient.setQueryData(listenerTagsQueryKey, context?.previousListenerTags);
    },
    onSuccess: (newTag, _variables, context) => {
      queryClient.setQueryData<ListenerTagResponse[]>(
        listenerTagsQueryKey,
        (optimisticTags) => optimisticTags?.map((tag) => (tag.id === context?.optimisticTag.id ? newTag : tag)) ?? [],
      );
      queryClient.invalidateQueries(['currentUser']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(listenerTagsQueryKey);
    },
  });
};

const useRemoveProfileTag = ({ id: tagId, listenerTagId }: ProfileTag) => {
  const queryClient = useQueryClient();
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'Current user has no listener role');

  const listenerTagsQueryKey = tagKeys.listenerTags(listenerRole.id);
  return useMutation({
    mutationFn: async () => {
      if (listenerTagId) await removeProfileTag(listenerRole.id, listenerTagId);
    },
    onMutate: async () => {
      await queryClient.cancelQueries(listenerTagsQueryKey);
      const previousListenerTags = queryClient.getQueryData<ListenerTagResponse[]>(listenerTagsQueryKey);

      queryClient.setQueryData<ListenerTagResponse[]>(
        listenerTagsQueryKey,
        (oldTags) => oldTags?.filter(({ tag_id: cachedTagId }) => cachedTagId !== tagId) ?? [],
      );

      return { previousListenerTags };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
    },
    onError: (_err, _vars, context) => {
      queryClient.setQueryData(listenerTagsQueryKey, context?.previousListenerTags);
    },
    onSettled: () => {
      queryClient.invalidateQueries(listenerTagsQueryKey);
    },
  });
};

export const useToggleProfileTag = (tag: ProfileTag) => {
  const addProfileTagMutation = useAddProfileTag(tag.id);
  const removeProfileTagMutation = useRemoveProfileTag(tag);
  const activeMutation = tag.listenerTagId === null ? addProfileTagMutation : removeProfileTagMutation;
  // This is necessary for TS to allow us to call mutate.
  // If we need to add options like onSuccess, we can include them in this type-def.
  const mutate: () => void = activeMutation.mutate;

  return {
    ...activeMutation,
    mutate,
    isLoading: addProfileTagMutation.isLoading || removeProfileTagMutation.isLoading,
  };
};

export const useAddBackgroundTag = (tagId: number) => {
  const queryClient = useQueryClient();
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'Current user has no listener role');

  return useMutation({
    mutationFn: async (documentation: BackgroundTagDocumentation) => {
      await addBackgroundTag(listenerRole.id, tagId, documentation);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tagKeys.listenerTags(listenerRole.id));
    },
  });
};

const GENDER_KEY = 'GENDER';
const PRONOUN_KEY = 'PRONOUN';
const RACE_KEY = 'RACE/ETHNICITY';
const SPIRITUALITY_KEY = 'SPIRITUALITY';
const LANGUAGE_KEY = 'LANGUAGE';
const FAMILY_KEY = 'FAMILY';
const RELATIONSHIP_KEY = 'RELATIONSHIP';
const OPTIONS_FILTER = [
  GENDER_KEY,
  PRONOUN_KEY,
  RACE_KEY,
  SPIRITUALITY_KEY,
  LANGUAGE_KEY,
  FAMILY_KEY,
  RELATIONSHIP_KEY,
].join(',');

const SEARCH_FOR_SUPPORT = 'SEARCH_FOR_SUPPORT';
const TAGGROUP_FILTER = [SEARCH_FOR_SUPPORT].join(',');

const fetchOptionTags = async () => {
  const { data } = await http.get<TagGroupResponse[]>('/tag_groups/', {
    params: { keys: OPTIONS_FILTER },
  });
  return data;
};

const fetchTagGroups = async () => {
  const { data } = await http.get<TagGroupResponse[]>('/tag_groups/', {
    params: { keys: TAGGROUP_FILTER },
  });
  return data;
};

const formatGroupToOptions = (group: TagGroupResponse) => group?.tags?.map(({ name }) => name);

export const useOptionTags = () =>
  useQuery({
    queryKey: tagKeys.optionTags(),
    queryFn: fetchOptionTags,
    select: (tags) => {
      const genderGroup = tags.find(({ key }) => key === GENDER_KEY);
      invariant(genderGroup, 'Gender options not found');
      const pronounGroup = tags.find(({ key }) => key === PRONOUN_KEY);
      invariant(pronounGroup, 'Pronoun options not found');
      const raceGroup = tags.find(({ key }) => key === RACE_KEY);
      invariant(raceGroup, 'Race/Ethnicity options not found');
      const spiritualityGroup = tags.find(({ key }) => key === SPIRITUALITY_KEY);
      invariant(spiritualityGroup, 'Spirituality options not found');
      const languageGroup = tags.find(({ key }) => key === LANGUAGE_KEY);
      invariant(languageGroup, 'Language options not found');
      const familyGroup = tags.find(({ key }) => key === FAMILY_KEY);
      invariant(familyGroup, 'Family options not found');
      const relationshipGroup = tags.find(({ key }) => key === RELATIONSHIP_KEY);
      invariant(relationshipGroup, 'Relationship options not found');

      return {
        genderOptions: formatGroupToOptions(genderGroup),
        pronounOptions: formatGroupToOptions(pronounGroup),
        raceOptions: formatGroupToOptions(raceGroup),
        spiritualityOptions: formatGroupToOptions(spiritualityGroup),
        languageOptions: formatGroupToOptions(languageGroup),
        familyOptions: formatGroupToOptions(familyGroup),
        relationshipOptions: formatGroupToOptions(relationshipGroup),
      };
    },
  });
