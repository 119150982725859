import { Button, Typography } from '@mui/material';
import useAuth from '../hooks/useAuth';

interface SignInButtonProps {
  color?: string;
}

export const SignInButton: React.FC<SignInButtonProps> = ({ color = 'primary.contrastText' }) => {
  const { keycloak } = useAuth();

  const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    /** This is used to prevent the Login page from redirecting back to this landing page when a user comes from here */
    event.preventDefault();
    if (keycloak) {
      keycloak?.login();
    }
  };

  return (
    <Button variant="text" onClick={handleLoginClick}>
      <Typography color={color}>Sign In</Typography>
    </Button>
  );
};
