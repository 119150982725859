import { LeftArrowIcon } from '@kindlyhuman/component-library';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { useSendKeycloakVerificationLink } from '../../common/http/hooks/user';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../routing/route-paths';
import { Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/kindly-human-logo-2-line.svg';
import useAuth from '../../common/hooks/useAuth';

interface VerificationPropsType {
  title?: string;
  transitionPage?: () => void;
}

const Verification: React.FC<VerificationPropsType> = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const hasRunOnce = useRef(false);
  const emailAddress = localStorage.getItem('emailAddress')!;
  const storedUserId = localStorage.getItem('userId')!;
  const sendKeycloakVerificationLinkMutation = useSendKeycloakVerificationLink();

  const sendVerificationLink = (isResend = false) => {
    sendKeycloakVerificationLinkMutation.mutate(
      {
        userId: storedUserId,
      },
      {
        onSuccess: () => {
          if (isResend) {
            Toast.success('Verification link sent successfully.');
          }
        },
        onError: (err: any) => {
          Toast.error(err.response.data.description);
        },
      },
    );
  };

  // Send the verification link as soon as the page loads
  useEffect(() => {
    if (hasRunOnce.current) return;
    hasRunOnce.current = true;
    sendVerificationLink();
  }, []);

  useEffect(() => {
    if (!storedUserId) {
      navigate(ROUTE_PATH.create.peer);
    }
  }, [storedUserId]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        color="primary.contrastText"
        alignItems="center"
        p={2}
        pb={5}
        mb={4}
        sx={{ background: ({ palette: { background } }) => background.patterned }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={() => logout()} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={logo} />
      </Box>

      <Box px={4}>
        <button
          onClick={() => navigate(ROUTE_PATH.create.peer)}
          className="flex no-wrap gap-x-2 font-medium text-dark txt-lg pb-8"
        >
          <LeftArrowIcon color="black" /> Back
        </button>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box p={4}>
          <h6 className="text-dark text-lg pt-8 leading-relaxed pb-10">
            We need to verify your email. We just sent you an email at <span className="font-bold">{emailAddress}</span>{' '}
            — please open it and click the link to verify your account.
          </h6>
        </Box>
        <Box pb={4}>
          <Button
            fullWidth
            data-testid="resend-email-button"
            variant="contained"
            onClick={() => {
              sendVerificationLink(true);
            }}
          >
            Resend Link to my Email
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Verification;
