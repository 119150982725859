import React from 'react';

import Spinner from '../../common/components/Spinner/Spinner';
import useAuth from '../../common/hooks/useAuth';

export default function Login() {
  const { keycloak } = useAuth();

  keycloak?.login();

  return <Spinner />;
}
