import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Link as MuiLink,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import { styled, type Theme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isAxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { ChangeEventHandler, ComponentProps, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { Link, useLocation } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { CallToActionFooter } from '../../common/components/CallToActionFooter';
import ChangePasswordModal from '../../common/components/ChangePasswordModal';
import LocCropper from '../../common/components/Loc-Cropper/Loc-Cropper';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import Spinner from '../../common/components/Spinner/Spinner';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import IconTooltip from '../../common/components/IconTooltip';
import { useTimezoneOptions } from '../../common/http/hooks/metadata';
import { useStateOptions } from '../../common/http/hooks/state-options';
import { useOptionTags } from '../../common/http/hooks/tags';
import { FormattedUser, useUpdateUser } from '../../common/http/hooks/user';
import { readFileToString } from '../../common/stringUtils';
import { useCurrentUser } from '../../contexts/user-context';
import { ROUTE_PATH } from '../../routing/route-paths';
import { SupportType } from '../Support/Support';
import { ProfileForm, useProfileFormSchema } from './useProfileFormSchema';
import { ProfilePhotoStatus } from '../../common/types';
import { CheckCircle } from '@mui/icons-material';
import { theme } from '../../theme';
import useAuth from '../../common/hooks/useAuth';
import { keycloakConfig } from '../../contexts/auth-context';

const NO_RACE = 'none';
const NO_SPIRITUALITY = 'none';
const NO_FAMILY = 'none';
const NO_RELATIONSHIP = 'none';
const NO_PRONOUN = 'none';

const getEmptyDropdownOption = (option: string, user: FormattedUser) => {
  return undefined;
};

const makeDefaultValues = (user: FormattedUser) => ({
  firstName: user.firstName ?? '',
  lastName: user.lastName ?? '',
  displayName: user.displayName ?? '',
  streetAddress1: user.streetAddress1 ?? '',
  streetAddress2: user.streetAddress2 ?? '',
  city: user.city ?? '',
  state: user.state ?? '',
  zip: user.zip ?? '',
  timezone: user.timezone ?? '',
  birthday: user.birthday?.toJSDate() ?? null,
  phoneNumber: user.phoneNumber ?? '',
  gender: user.gender ?? '',
  pronouns: !user.pronouns || user.pronouns === 'none' ? getEmptyDropdownOption(NO_PRONOUN, user) : user.pronouns,
  race: !user.race || user.race === 'none' ? getEmptyDropdownOption(NO_RACE, user) : user.race,
  spirituality:
    !user.spirituality || user.spirituality === 'none'
      ? getEmptyDropdownOption(NO_SPIRITUALITY, user)
      : user.spirituality,
  family: !user.family || user.family === 'none' ? getEmptyDropdownOption(NO_FAMILY, user) : user.family,
  relationship:
    !user.relationship || user.relationship === 'none'
      ? getEmptyDropdownOption(NO_RELATIONSHIP, user)
      : user.relationship,
  languages: user.languages ?? [],
  howDidYouhear: user.howDidYouHear ?? '',
  email: user.emailAddress ?? '',
  currentProfilePhoto: user.listenerRole?.currentProfilePhoto?.fileUrl ?? '',
  proposedProfilePhoto: user.listenerRole?.proposedProfilePhoto?.fileUrl ?? '',
});

export enum ProfileType {
  ONBOARDING,
  HOME,
}

interface ProfilePropsType {
  title?: string;
  profileType?: ProfileType;
  transitionPage?: () => void;
}

const Profile: React.FC<ProfilePropsType> = ({ title = 'Profile', profileType = ProfileType.HOME, transitionPage }) => {
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const user = useCurrentUser();
  invariant(user.listenerRole, 'Listener role is not defined on user');

  const stateOptionsQuery = useStateOptions();
  const timezoneOptionsQuery = useTimezoneOptions();
  const optionTagsQuery = useOptionTags();

  const updateUserMutation = useUpdateUser();

  const { url, realm, clientId } = keycloakConfig;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();

  const {
    control,
    formState: { errors, touchedFields, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<ProfileForm>({
    defaultValues: makeDefaultValues(user),
    resolver: yupResolver(useProfileFormSchema()),
  });

  const setNewProfilePhotoSrc = (newProfilePhotoSrc: string, shouldValidate = false) => {
    setValue('newProfilePhotoSrc', newProfilePhotoSrc, { shouldValidate, shouldDirty: true, shouldTouch: true });
  };

  const onAddProfileImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const profileImage = e.target.files?.[0];
    if (profileImage) {
      const newProfilePhotoSrc = await readFileToString(profileImage);
      setNewProfilePhotoSrc(newProfilePhotoSrc, true);
    }
  };

  const submit = (values: ProfileForm) => {
    const { newProfilePhotoSrc: _newProfilePhotoSrc, ...rest } = values;
    updateUserMutation.mutate(touchedFields.newProfilePhotoSrc ? values : rest, {
      onSuccess: ({ formattedUser: updatedUser }) => {
        reset(makeDefaultValues(updatedUser));
        Toast.success('Your changes have been saved.');
        transitionPage && transitionPage();
      },
      onError: (err) => {
        if (isAxiosError(err))
          Toast.error(err.response?.data?.description ?? 'There was a problem updating your profile.');
        else console.error(err);
      },
    });
  };

  const getProfilePicLabel = () => {
    if (
      !user.listenerRole?.currentProfilePhoto &&
      !user.listenerRole?.proposedProfilePhoto &&
      !watch('newProfilePhotoSrc')
    ) {
      return '';
    } else if (user.listenerRole?.currentProfilePhoto && !user.listenerRole?.proposedProfilePhoto) {
      return 'Replace';
    } else if (!user.listenerRole?.proposedProfilePhoto && watch('newProfilePhotoSrc')) {
      return 'Pending';
    } else if (user.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.pending) {
      return 'In Review';
    } else if (user.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.rejected) {
      return 'Revisit';
    }
  };

  const getPendingProfilePicLabelColor = () => {
    if (user.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.rejected) {
      return theme.palette.error.main;
    } else if (
      watch('newProfilePhotoSrc') ||
      user.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.pending
    ) {
      return '#FFA726';
    }
  };

  const getDisplayNameErrorMessage = () => {
    if (errors.displayName) return errors.displayName.message;
    if (!watch('displayName')) return null;
    if (user.displayNameRejected && watch('displayName') === user.displayName) {
      return 'Your display name has been rejected, please provide a new display name';
    }
    return null;
  };

  const keycloakUpdatePassword = () => {
    const keycloakUrl = new URL(`${url}/realms/${realm}/protocol/openid-connect/auth`);
    keycloakUrl.searchParams.append('client_id', clientId);
    keycloakUrl.searchParams.append('redirect_uri', `${baseUrl}/home/profile`);
    keycloakUrl.searchParams.append('response_type', 'code');
    keycloakUrl.searchParams.append('scope', 'openid');
    keycloakUrl.searchParams.append('kc_action', 'UPDATE_PASSWORD');

    window.location.href = keycloakUrl.toString();
  };

  // When the user successfully updates their password via keycloak, we show a success message and clear the query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('kc_action_status') === 'success') {
      Toast.success('Password updated successfully!');
    }
  }, [location]);

  if (!stateOptionsQuery.data || !timezoneOptionsQuery.data || !optionTagsQuery.data) return <Spinner />;

  return (
    <>
      {profileType === ProfileType.HOME && (
        <PageNavigator
          saveNeeded={isDirty && !location.pathname.includes('onboarding')}
          save={handleSubmit(submit)}
          clear={reset}
        />
      )}
      {watch('newProfilePhotoSrc') && touchedFields.newProfilePhotoSrc && (
        <LocCropper setProfileImage={setNewProfilePhotoSrc} image={watch('newProfilePhotoSrc')} />
      )}
      <form onSubmit={handleSubmit(submit)}>
        <Box px={3} pt={3}>
          <Typography variant="h5" fontWeight={800}>
            {title}
          </Typography>
          <Box pt={3} />

          <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
            <Typography variant="caption" display="flex" alignItems="center" gap={1}>
              PROFILE PICTURE
              <IconTooltip title='You may select a professional picture of yourself or choose to use a personalized avatar. Please see "View Samples" for acceptable submissions.' />
            </Typography>
            <MuiLink
              component="a"
              href="https://hello.kindlyhuman.io/peer-standards"
              variant="body1"
              underline="none"
              fontWeight={600}
              target="_blank"
              rel="noreferrer"
            >
              View Samples
            </MuiLink>
          </Box>
          <br />
          <Box display="flex" gap={8}>
            {/* current profile photo widget */}
            {user.listenerRole?.currentProfilePhoto && (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={<CheckCircle sx={{ color: 'green' }} />}
              >
                <Box display="flex" flexDirection="column" gap={1} justifyContent="center">
                  <Avatar
                    sx={{ width: 125, height: 125, cursor: 'pointer' }}
                    alt="Profile Image"
                    src={user.listenerRole?.currentProfilePhoto.fileUrl}
                  />
                  <Typography
                    variant="h6"
                    display="flex"
                    justifyContent="center"
                    color={theme.palette.success.main}
                    alignItems="center"
                  >
                    Active
                  </Typography>
                </Box>
              </Badge>
            )}

            {/* proposed profile photo widget */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                <label>
                  {watch('newProfilePhotoSrc') ? (
                    <RemoveProfileIcon sx={{ bgcolor: getPendingProfilePicLabelColor() }} />
                  ) : (
                    <AddProfileIcon sx={{ bgcolor: errors.newProfilePhotoSrc ? 'error.main' : 'primary.main' }} />
                  )}
                  <input hidden type="file" accept=".jpg, .jpeg, .png, .webp" onChange={onAddProfileImage} />
                </label>
              }
            >
              <Box display="flex" flexDirection="column" gap={1} justifyContent="center">
                <Avatar
                  sx={{ width: 125, height: 125, cursor: 'pointer' }}
                  alt="Profile Image"
                  src={watch('newProfilePhotoSrc') || user.listenerRole?.proposedProfilePhoto?.fileUrl}
                />
                <Typography
                  variant="h6"
                  display="flex"
                  justifyContent="center"
                  color={getPendingProfilePicLabelColor()}
                  alignItems="center"
                >
                  {getProfilePicLabel()}
                </Typography>
                {errors.newProfilePhotoSrc && (
                  <Typography variant="body2" color="error.main">
                    {errors.newProfilePhotoSrc.message}
                  </Typography>
                )}
              </Box>
            </Badge>
          </Box>

          <Box pt={3} />
          <Stack gap={3}>
            <Box display="flex" gap={2}>
              <TextField
                label="FIRST NAME"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                autoComplete="given-name"
                fullWidth
                {...register('firstName')}
              />
              <TextField
                label="LAST NAME"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                autoComplete="family-name"
                fullWidth
                {...register('lastName')}
              />
            </Box>
            <Box display="flex" gap={1} alignItems="center">
              <TextField
                label="DISPLAY NAME"
                error={Boolean(getDisplayNameErrorMessage())}
                helperText={getDisplayNameErrorMessage()}
                autoComplete="display-name"
                fullWidth
                {...register('displayName')}
              />
              <IconTooltip title="This name will surface to Members. You are permitted to use an alias." />
            </Box>
            <Typography variant="caption" fontSize="16px" fontWeight="400">
              Please enter a mailing address, no P.O. boxes accepted
            </Typography>

            <Typography
              variant="caption"
              display="flex"
              alignItems="center"
              color="primary"
              fontWeight="700"
              fontSize="14px"
              gap={1}
            >
              Why do you need my address?
              <IconTooltip title="In order to process any 1099's that may be needed, your address is required." />
            </Typography>

            <TextField
              label="STREET ADDRESS 1"
              error={Boolean(errors.streetAddress1)}
              helperText={errors.streetAddress1?.message}
              autoComplete="address-line1"
              fullWidth
              {...register('streetAddress1')}
            />
            <TextField
              label="STREET ADDRESS 2"
              error={Boolean(errors.streetAddress2)}
              helperText={errors.streetAddress2?.message}
              autoComplete="address-line2"
              fullWidth
              {...register('streetAddress2')}
            />

            <Box display="flex" gap={2}>
              <TextField
                label="CITY"
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
                autoComplete="address-level2"
                fullWidth
                {...register('city')}
              />
              <Controller
                name="state"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="STATE"
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                    inputProps={field}
                  >
                    {stateOptionsQuery.data?.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>

            <Box display="flex" gap={2}>
              <TextField
                label="ZIP CODE"
                error={Boolean(errors.zip)}
                helperText={errors.zip?.message}
                autoComplete="postal-code"
                fullWidth
                {...register('zip')}
              />
              <Controller
                name="timezone"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="TIMEZONE"
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                    inputProps={field}
                  >
                    {timezoneOptionsQuery.data?.map((timezone) => (
                      <MenuItem key={timezone} value={timezone}>
                        {timezone.replace('_', ' ')}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>

            <Controller
              name="birthday"
              control={control}
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <DatePicker
                  label="DATE OF BIRTH"
                  format="MM/dd/yyyy"
                  onOpen={() => {
                    if (!value) setValue('birthday', new Date(1990, 0, 1));
                  }}
                  openTo="year"
                  disableFuture
                  slotProps={{
                    textField: { error: Boolean(error), helperText: error?.message, fullWidth: true },
                    actionBar: { sx: { '.MuiButtonBase-root': { color: 'primary.main', width: 'unset' } } },
                  }}
                  sx={{ '& .MuiPickersLayout-root': { display: 'block' } }}
                  // the DatePicker wants luxon DateTimes, but RHF wants JS Dates, so we have to convert them here.
                  value={value && DateTime.fromJSDate(value)}
                  onChange={(date, ...args) => onChange(date?.toJSDate() ?? '', ...args)}
                  {...field}
                />
              )}
            />

            <TextField
              label="PHONE NUMBER"
              type="tel"
              autoComplete="tel-national"
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              InputProps={{
                inputComponent: PhoneNumberMask,
                value: watch('phoneNumber'),
                onBlur: () => {
                  trigger('phoneNumber');
                },
              }}
              fullWidth
              {...register('phoneNumber')}
            />

            <Box display="flex" gap={2}>
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="GENDER"
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                    inputProps={field}
                  >
                    {optionTagsQuery.data.genderOptions?.map((gender) => (
                      <MenuItem key={gender} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="pronouns"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="PRONOUNS"
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                    inputProps={field}
                  >
                    {optionTagsQuery.data.pronounOptions?.map((pronoun) => (
                      <MenuItem key={pronoun} value={pronoun}>
                        {pronoun}
                      </MenuItem>
                    ))}
                    <MenuItem value={NO_PRONOUN}>Prefer not to say</MenuItem>
                  </TextField>
                )}
              />
            </Box>

            <TextField
              label="EMAIL"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              autoComplete="email"
              disabled={profileType === ProfileType.ONBOARDING}
              fullWidth
              {...register('email')}
            />

            <Controller
              name="race"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="RACE / ETHNICITY"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                  inputProps={field}
                >
                  {optionTagsQuery.data.raceOptions?.map((race) => (
                    <MenuItem key={race} value={race}>
                      {race}
                    </MenuItem>
                  ))}
                  <MenuItem value={NO_RACE}>Prefer not to say</MenuItem>
                </TextField>
              )}
            />

            <Controller
              name="languages"
              control={control}
              defaultValue={[]}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <InputLabel id="languages-select-label">LANGUAGES</InputLabel>
                  <Select
                    labelId="languages-select-label"
                    id="languages-select"
                    multiple
                    error={Boolean(error)}
                    input={<OutlinedInput label="Languages" />}
                    renderValue={(selected) => selected.join(', ')}
                    {...field}
                  >
                    {optionTagsQuery.data.languageOptions?.map((language) => (
                      <MenuItem key={language} value={language}>
                        <Checkbox checked={field.value.includes(language)} />
                        <ListItemText primary={language} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true} id="languages-helper-text">
                    {error?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name="spirituality"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="SPIRITUALITY"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                  inputProps={field}
                >
                  {optionTagsQuery.data.spiritualityOptions?.map((spirituality) => (
                    <MenuItem key={spirituality} value={spirituality}>
                      {spirituality}
                    </MenuItem>
                  ))}
                  <MenuItem value={NO_SPIRITUALITY}>Non-specified</MenuItem>
                </TextField>
              )}
            />

            <Controller
              name="relationship"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="RELATIONSHIP"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                  inputProps={field}
                >
                  {optionTagsQuery.data.relationshipOptions?.map((relationship) => (
                    <MenuItem key={relationship} value={relationship}>
                      {relationship}
                    </MenuItem>
                  ))}
                  <MenuItem value={NO_RELATIONSHIP}>Prefer not to say</MenuItem>
                </TextField>
              )}
            />

            <Controller
              name="family"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  label="FAMILY"
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                  inputProps={field}
                >
                  {optionTagsQuery.data.familyOptions?.map((family) => (
                    <MenuItem key={family} value={family}>
                      {family}
                    </MenuItem>
                  ))}
                  <MenuItem value={NO_FAMILY}>Prefer not to say</MenuItem>
                </TextField>
              )}
            />
            {profileType === ProfileType.ONBOARDING && (
              <>
                <Typography variant="caption" display="flex" alignItems="center" gap={1}>
                  HOW DID YOU HEAR ABOUT US?
                  <IconTooltip title="Please type the name of the individual, platform or organization you were referred to our website from." />
                </Typography>
                <TextField
                  error={Boolean(errors.howDidYouHear)}
                  helperText={errors.howDidYouHear?.message}
                  multiline
                  rows={3}
                  fullWidth
                  {...register('howDidYouHear')}
                />
              </>
            )}

            {profileType === ProfileType.HOME && (
              <MuiLink
                component="p"
                variant="body1"
                underline="none"
                fontWeight={800}
                sx={{ cursor: 'pointer', textAlign: 'center' }}
                onClick={() => keycloakUpdatePassword()}
              >
                Change Password
              </MuiLink>
            )}
          </Stack>
          <Box pt={3} />
        </Box>

        <CallToActionFooter>
          <LoadingButton
            loading={updateUserMutation.isLoading}
            disabled={(profileType === ProfileType.HOME ? !isDirty : false) || Object.keys(errors).length > 0}
            type="submit"
            variant="contained"
          >
            {profileType === ProfileType.HOME ? 'Save' : 'NEXT'}
          </LoadingButton>
          {user.listenerRole?.isListener && (
            <Link
              to={ROUTE_PATH.support}
              style={{ textDecoration: 'none' }}
              state={{ type: SupportType.LISTENER_DOWNGRADE }}
            >
              <Box sx={{ cursor: 'pointer' }} mt={4}>
                <Typography color="common.white" fontWeight={800}>
                  Contact Care Team
                </Typography>
                <Typography color="common.white" fontWeight={800}>
                  about peer role.
                </Typography>
              </Box>
            </Link>
          )}
        </CallToActionFooter>
      </form>

      {isChangingPassword && (
        <ChangePasswordModal openState={isChangingPassword} close={() => setIsChangingPassword(false)} />
      )}
    </>
  );
};

const profileIconStyles = (theme: Theme) => ({
  width: 35,
  height: 35,
  borderRadius: '50%',
  color: theme.palette.text.light,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.palette.background.paper,
  cursor: 'pointer',
});
const RemoveProfileIcon = styled(CloseIcon)(({ theme }) => profileIconStyles(theme));
const AddProfileIcon = styled(AddIcon)(({ theme }) => profileIconStyles(theme));

const PhoneNumberMask = React.forwardRef((props: ComponentProps<'input'>, ref: React.ForwardedRef<ReactInputMask>) => (
  <InputMask
    {...props}
    ref={ref}
    maskPlaceholder={null}
    mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
  />
));

export default Profile;
